import React, {useState} from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import AnimatedHeading from 'components/animated-heading'

const Section = styled.section`
            position: relative;
            overflow: hidden;
            background-color: #111;
            background-image: url(${props => props.urlimage.background.childImageSharp.fluid.src});
            background-size: cover;
            .heading {
                width: 100%;
            }
          }
        `

const ServiceContainer = styled.div`
            background-color: rgba(0,0,0,.7);
            padding: 100px 0;
        `


const ServiceElement = styled.div`
            margin-bottom: 20px;
            text-align: center;
            padding: 40px;
            border-radius: 20px;
            transition: .2s;
            &:hover {
                background-color: #000;
                box-shadow: 0 28px 60px rgb(4, 229, 229, .1);
                transform: translateY(-2px);
            }
        `
const ServiceHeading = styled.h4`
            font-size: 30px;
            font-weight: 500;
            font-family: Teko;
            color: #fff;
        `
const ServiceSeparator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 10px;
            margin: auto;
         `
const ServiceIcon = styled.div`
            margin-bottom: 20px;
            img {
                max-height: 70px;
            }
        `

const ServiceList = styled.ul`
            padding: 0;
            margin: 11px 0 0 0;
        `

const ServiceListElement = styled.li`
            list-style: none;
            color: #c5c5c5;
            font-weight: 300;
            font-size: 14px;
            margin: 5px 0px;
            &::before {
                display: inline-block;
                content: '';
                border-radius: 2.5px;
                height: 5px;
                width: 5px;
                margin-right: 10px;
                background-color:#04e5e5;
                position: relative;
                bottom: 2.5px;
            }
        `

export default function ServicesOne() {
    const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: {eq: "services.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            src
          }
        }
      }   
      }   
    `)
    const [showDetails, setShowDetails] = useState();
    function ShowDetails(){

    }
    return (
        <Section id="Service" urlimage={data}>
            <ServiceContainer>
                <Container>
                    <AnimatedHeading text="Unsere Dienstleistungen" />
                    <Row>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInLeft" delay={100}>
                                <ServiceElement >
                                    <ServiceHeading>
                                        KFZ-Reparaturen
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            TÜV Abnahme
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Bremse, Auspuff, Stoßdämpfer
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInDown" delay={200}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Inspektionen
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Ölwechsel
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Filterwechsel
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Lämpchen tauschen
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Bremsflüssigkeitwechsel
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInRight" delay={300}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Karosseriebau
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Ausbeulen
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Unfallinstandsetzung
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Karosserie Anfertigung
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInLeft" delay={400}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Lackiererarbeiten
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Einfarbiges Auto
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Zweifarbiges Auto
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Buntes Auto
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInUp" delay={500}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Autoglas
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Scheibentausch
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={4}>
                            <AnimationContainer animation="fadeInRight" delay={600}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Tuning
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Breiter
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Schneller
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Höher
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                        <Col md={12}>
                            <AnimationContainer animation="fadeInRight" delay={700}>
                                <ServiceElement>
                                    <ServiceHeading>
                                        Restauration
                                    </ServiceHeading>
                                    <ServiceSeparator />
                                    <ServiceList>
                                        <ServiceListElement>
                                            Rost
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Polster
                                        </ServiceListElement>
                                        <ServiceListElement>
                                            Zeitgemäß
                                        </ServiceListElement>
                                    </ServiceList>
                                </ServiceElement>
                            </AnimationContainer>
                        </Col>
                    </Row>
                </Container>
            </ServiceContainer>
        </Section>
    )
}